<template>
    <div>
        <div v-if="element.optionHere" class="flex ml-3">
            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 15 14" fill="none" class="optionCircle">
               <circle cx="7.0332" cy="7" r="7" fill="#0D69D5"/>
               <path d="M4.0332 7L10.0332 7" stroke="white" stroke-width="2" stroke-linecap="round"/>
            </svg>
         <p class="optionHeading ml-3">{{element.optionLabel}}</p>
        </div>
        <div v-else class="flex items-center gap-3 w-full">
        <font-awesome-icon v-if="!element?.ristrictDrag"  icon="grip-vertical" class="text-base-content-300 text-gray-500" />
        <!--<font-awesome-icon icon="grip-vertical" class="text-gray-500" />-->
        <div v-if="element?.ristrictDrag" class="ml-2"></div>
        <div :class="[showSidePanel && activeFieldPanel.field.id === element.id ?
            'border-solid  bg-primary-200 border-primary border-r-4 border-0' :
            'hover:bg-primary-200', fieldClass]"
            class="check-field bg-gray-100 transition-all duration-200 px-2 py-1 flex justify-between items-center gap-3 rounded-xl cursor-pointer flex-grow">
            <div class="flex gap-3 items-center text-sm FieldInfo">
                <!-- <input v-if="!['Candidate Consent','Candidate Optional Consent'].includes(element.type)" v-model="element.label" @click.stop="$emit('fieldNameClick', element)" @blur="$emit('updateFieldLabel', { section, element })" :class="!element.label ? 'border border-dashed border-red-300 ' : ''" type="text" class="section_name bg-transparent min-w-100 border-0 outline-none p-1 text-md text-gray-600 focus:bg-gray-200 rounded focus:border-none" /> -->
                <!-- <input v-else readonly :value="element.type.replace('Candidate','')" v-tippy content="can't change consent label" type="text" class="section_name bg-transparent min-w-100 border-0 outline-none p-1 text-md text-gray-600 focus:bg-gray-200 rounded focus:border-none" /> -->
                <input v-if="edited" v-model="element.label" @click.stop="$emit('fieldNameClick', element)"
                    @blur="onUpdateFieldLabel(section, element)"
                    :class="!element.label ? 'border border-dashed border-red-300 ' : ''" type="text"
                    class="section_name w-48 bg-transparent border-0 outline-none p-1 text-md text-base-content focus:bg-gray-200 rounded focus:border-none border-b border-dashed border-gray-300 truncate" />
                <span v-else v-tippy :content="fieldLabel"
                    class="section_name bg-transparent truncate w-48 border-0 outline-none p-1 text-md text-base-content font-semibold truncate">{{
                        fieldLabel }}</span>
                <div v-if="this.$route.params.action !== 'view' && ISEDITPERMISSIONS"
                    class="h-8 w-8 flex items-center justify-center rounded-half hover:bg-dvbrandcolor hover:text-white-text transition-all duration-150"
                    :class="edited ? 'bg-dvbrandcolor text-white-text' : 'text-primary'" @click.stop="onEdit()">
                    <font-awesome-icon icon="edit" class="cursor-pointer text-sm fill-current"
                        v-bind:style="{ opacity: this.$route.params.action !== 'view' ? 1 : 0.5, cursor: this.$route.params.action !== 'view' ? 'pointer' : 'not-allowed' }" />
                </div>
                <span v-tippy :content="getFieldName(element.name)">{{ getElementName }}</span>
                <GapInfo v-if="element.use_in_gap_validation" :element="element"
                    @click.native.stop="ISEDITPERMISSIONS && $emit('showGapValidationModal') && $route.params.action !== 'view'"
                    v-bind:style="{ opacity: this.$route.params.action !== 'view' ? 1 : 0.5, cursor: this.$route.params.action !== 'view' ? 'pointer' : 'not-allowed' }" />
            </div>
            <div class="gap-3.5 flex items-left ml-auto" :class="{ 'FieldMargin': inFormAdmin}">
                <div class="gap-3 flex items-center mr-10 pr-10">
                    <FieldTag v-if="element.ui_label || element.field_type" :label="element.ui_label || element.field_type"
                        :title="getTitle(element)" />
                    <FieldTag class="bg-gray-200"
                        :label="element.is_custom ? 'Custom' : element?.is_restricted ? 'Restricted' : 'System Provided'"
                        :title="`Field Source: ${element.is_custom ? 'Custom' : element?.is_restricted ? 'Restricted' : 'System Provided'}`" />
                </div>
                <div class="block_visible">
                <Checkbox
                    v-if="element.block_id"
                    @click.native.stop
                    v-model="useblockvisible"
                    @input="changeblockVisibile(element)"
                    :disabled="this.$route.params.action === 'view' || !ISEDITPERMISSIONS"
                    />
                </div> 
                <FieldRolePanel
                    v-if="ISEDITPERMISSIONS"
                    class="role-panel"
                    :field="element"
                    @PermissionChange="PermissionChange"
                    @change="change"
                    @changeCaseCreation="changeCaseCreation"
                    :allReportsRoles="allReportsRoles"
                    :isLoading="rolesLoading"
                    :class={packageFieldRole:inPackage}
                    :inCheck="inCheck"
                />
            </div>
            <div class="flex items-center justify-center">
                <font-awesome-icon v-if="this.$route.params.action !== 'view' && showDeleteIcon && ISEDITPERMISSIONS && !element?.ristrictDrag"
                    class="cursor-pointer text-red-500 hover:text-red-700"
                    @click.stop="$emit('handleDeleteField', { element, section })" icon="trash"
                    v-bind:style="{ opacity: this.$route.params.action !== 'view' ? 1 : 0.5, cursor: this.$route.params.action !== 'view' ? 'pointer' : 'not-allowed' }" />
            </div>
        </div>
    </div>
    </div>    
</template>

<script>
import FieldRolePanel from "./field-roles-panel";
import FieldTag from "./FieldTag";
const GapInfo = () => import("./GapInfo.vue");
import Checkbox from "@/components/checkbox-alt";

export default {
    name: "fields",
    props: {
        showSidePanel: {
            type: Boolean,
            default: false,
        },
        activeFieldPanel: {
            type: Object,
            default: () => { },
        },
        element: {
            type: Object,
            default: () => { },
        },
        section: {
            type: Object,
            default: () => { },
        },
        allReportsRoles: {
            type: Array,
            default: () => [],
        },
        fieldClass: {
            type: String,
            default: '',
        },
        updateFieldAttributesMethod: {
            type: Function,
        },
        showDeleteIcon: {
            type: Boolean,
            default: true
        },
        draggable: {
            type: Boolean,
            default: true
        },
        ISEDITPERMISSIONS: {
            type: Boolean,
            default: true
        },
        inPackage:{
            type: Boolean,
        },
        inCheck:{
            type: Boolean,
        }
    },
    components: {
        FieldRolePanel,
        GapInfo,
        FieldTag,
        Checkbox
    },
    data() {
        return {
            edited: false,
            rolesLoading: false,
            newValue: '',
            oldValue: '',
        }
    },
    computed: {
        getElementName() {
            let text = ''
            const max_length = 25;
            if (this.element.name && this.element.name.length > max_length) {
                text = this.element.name.substring(0, max_length) + ' ...'
                return text
            }else{
                return this.element.name
            }
            
        },
        fieldLabel() {
            return this.getFieldLabel(this.element);
        },
        useblockvisible: {
            get() {
                return this.element.block_visibility
            },
            set(val) {
                this.element.block_visibility = val;
            },
        }
    },
    mounted() {
        this.oldValue = this.element.label;
    },
    methods: {
        getTitle(element) {
            if (element.ui_label)
                return `Field Type:` + element.ui_label;
            else if (element.field_type)
                return `Field Type:` + element.field_type;
        },
        PermissionChange(role) {
            this.$emit("PermissionChange", role);
            // this.change();
        },
        changeblockVisibile(element) {
            this.$emit('changeblockVisibile', element);
        },
        async change({ role, backup }, isRole) {
            // console.log('role', role)
            this.rolesLoading = true;
            try {
                // this.$emit('updateFieldAttributesMethod', {section:this.section, element:this.element})    
                await this.updateFieldAttributesMethod(this.section, this.element);
            } catch (error) {
                if (isRole) {
                    let match = this.element.roles.find((rl) => role.role_id === rl.role_id)
                    Object.assign(match, backup);
                }
            }
            this.rolesLoading = false;
        },
        getFieldLabel(field) {
            const sanitizedContent = field.label?.replace(/<[^>]+>/g, (match) => {
                return match?.replace(/style="[^"]*"/g, '');
            });
            return sanitizedContent.length > 500 ? sanitizedContent.substring(0, 497) + "..." : sanitizedContent;
        },
        getFieldName(field) {
            const sanitizedContent = field?.replace(/<[^>]+>/g, (match) => {
                return match?.replace(/style="[^"]*"/g, '');
            })
            return sanitizedContent.length > 500 ? sanitizedContent.substring(0, 497) + "..." : sanitizedContent;
        },
        changeCaseCreation({ field }) {
            this.$emit("changeCaseCreation", field);
        },
        onEdit() {
            if (this.$route.params.action == 'view') {
                return
            }
            if (!this.element.label) {
                this.$toast.error("Label cannot be empty");
                return;
            }
            this.edited = !this.edited;
        },
        onUpdateFieldLabel(section, element) {
            this.edited = false;
            if (!element.label) {
                this.$toast.error("Label cannot be empty");
                return;
            }
            // console.log('onUpdateFieldLabel', element, this.element)
            this.$emit('updateFieldLabel', { section, field: element })
            if (!this.element?.is_custom && this.element?.is_restricted) {
                this.element.label = this.oldValue;
            }
        }
    },
    watch: {
        'element.label': function (newVal, oldVal) {
            this.newValue = newVal;
            console.log(oldVal);
        },
    },
};
</script>

<style lang="scss" scoped>
.check-field {
    display: grid;
    grid-template-columns: minmax(400px, auto) 1fr 50px;
    grid-auto-flow: column;

}

.block_visible {
    position: relative;
    left: -33px;
    width: 23px;
}
.packageFieldRole{
    margin-left:1.9%;
}
.optionHeading{
    color: #0236bb;
font-weight: 500;
}
.optionCircle{
    margin-top:5px;
}
</style>
